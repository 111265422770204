import { useState, useEffect, useContext } from "react";

import { useNavigate } from 'react-router-dom';

import be_api from "./../../modules/be_api";
import fe_api from "./../../modules/fe_api";

import Navbar from "./../../components/Navbar";
import MatchForm from "./../../components/Forms/FormMatch";

import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Spinner from 'react-bootstrap/Spinner';
import * as Icon from 'react-bootstrap-icons';

import { Context } from "./../../context/authContext";

function Home(){

    const navigate = useNavigate();

    const { handleLogout, triggerModal, setSuccessAction, successAction } = useContext(Context);

    const [dayCards, setDayCards] = useState('today');
    const [dayFetch, setDayFetch] = useState('null');
    const [daySync, setDaySync] = useState('null');

    const [nowActive, setNowActive] = useState(false);
    const [yesterdayActive, setYesterdayActive] = useState(false);
    const [todayActive, setTodayActive] = useState(true);
    const [tomorrowActive, setTomorrowActive] = useState(false);

    const [busy, setBusy] = useState(false);

    const [buttonFetchTodayState, setButtonFetchTodayState] = useState('active');
    const [buttonFetchYesterdayState, setButtonFetchYesterdayState] = useState('active');
    const [buttonFetchTomorrowState, setButtonFetchTomorrowState] = useState('active');
    const [buttonDeleteAllState, setButtonDeleteAllState] = useState('active');
    const [buttonSyncTodayState, setButtonSyncTodayState] = useState('active');
    const [buttonSyncYesterdayState, setButtonSyncYesterdayState] = useState('active');
    const [buttonSyncTomorrowState, setButtonSyncTomorrowState] = useState('active');

    const [serverStatusOn, setServerStatusOn] = useState(true);

    const [matches, setMatches] = useState('loading');

    const [test, setTest] = useState('testeeee');

    function resetSpinners(){

        setButtonFetchTodayState('active');
        setButtonFetchYesterdayState('active');
        setButtonFetchTomorrowState('active');
        setButtonDeleteAllState('active');

        setButtonSyncTodayState('active');
        setButtonSyncYesterdayState('active');
        setButtonSyncTomorrowState('active');


    }

    async function getServerStatus(){

        const url = '/';

        try{

            await be_api.get(url).then(function(response){

                setServerStatusOn(true);
    
            }).catch(function (error){
    
                setServerStatusOn(false);
    
            });
            
        }catch(error){

            setServerStatusOn(false);
            
        }

    }
    
    async function getMatches(day){

        switch (day){

            case 'now':
                
                setNowActive(true);
                setYesterdayActive(false);
                setTodayActive(false);
                setTomorrowActive(false);

                break;

            case 'yesterday':
                
                setNowActive(false);
                setYesterdayActive(true);
                setTodayActive(false);
                setTomorrowActive(false);

                break;

            case 'today':
                
                setNowActive(false);
                setYesterdayActive(false);
                setTodayActive(true);
                setTomorrowActive(false);

                break;
        
            case 'tomorrow':
                
                setNowActive(false);
                setYesterdayActive(false);
                setTodayActive(false);
                setTomorrowActive(true);

                break;                

            default:

                break;

        }

        const url = 'return_matches_of_day?day=' + day;

        const status = await be_api.get(url).then(function(response){

            if(response.data.status == 'sucesso'){

                setMatches(response.data.json);

            }else{

                setMatches('error');

            }

            return response.data.status;

        }).catch(function (error){

            setMatches('error');

            return error;

        });

        return status;

    }

    async function fetchMatches(day){

        setBusy(true);
        
        const url = '/run_fetch_matches?day=' + day;

        await be_api.get(url).then(function(response){

            setDayFetch('null');

            if(response.data.status == 'sucesso'){

                setSuccessAction(true);
                
            }else{

                setSuccessAction(false);

            }

        }).catch(function (error){

            setSuccessAction(false);

        });

        resetSpinners();
        setBusy(false);

    }

    async function deleteAll(){

        setButtonDeleteAllState('loading');
        setBusy(true);

        const url = '/run_delete_all_matches';

        await be_api.get(url).then(function(response){

            if(response.data.status == 'sucesso'){

                setSuccessAction(true);
                
            }else{

                setSuccessAction(false);

            }

        }).catch(function (error){

            setSuccessAction(false);

        });

        resetSpinners();
        setBusy(false);

        getMatches('today');

    }

    async function syncMatches(day){

        setBusy(true);

        const url = 'update_local_matches_from_remote.php?day=' + day;

        await fe_api.get(url).then(function(response){

            if(response.data.status == 'sucesso'){

                setSuccessAction(true);
                
            }else{

                setSuccessAction(false);

            }

        }).catch(function (error){

            setSuccessAction(false);

        });

        resetSpinners();
        setBusy(false);

    }

    function fetchMatchesYesterday(){

        setButtonFetchYesterdayState('loading');
        setDayFetch('yesterday');

    }

    function fetchMatchesToday(){
        
        setButtonFetchTodayState('loading');
        setDayFetch('today');

    }

    function fetchMatchesTomorrow(){

        setButtonFetchTomorrowState('loading');
        setDayFetch('tomorrow');

    }

    function syncMatchesYesterday(){

        setButtonSyncYesterdayState('loading');
        setDaySync('yesterday');

    }

    function syncMatchesToday(){

        setButtonSyncTodayState('loading');
        setDaySync('today');

    }

    function syncMatchesTomorrow(){

        setButtonSyncTomorrowState('loading');
        setDaySync('tomorrow');

    }

    function openAddMatch(){

        triggerModal("create", <MatchForm edit={false} />);

    };

    function openEditMatch(id){

        triggerModal("edit", <MatchForm edit={true} id={id}/>);

    };

    async function deleteMatch(id){

        const url = '/delete_match?id=' + id;

        await be_api.delete(url).then(function(response){

            if(response.data.status == 'sucesso'){

                setSuccessAction(true);

            }else{

                setSuccessAction(false);

            }

        }).catch(function (error){

            setSuccessAction(false);

        });

    }

    function renderCards(cardInfos){

        try{

            if(cardInfos == 'loading'){

                return (
                        
                    <div class="text-dark mt-4">
    
                        <p>Carregando...</p>
    
                    </div>
                
                );
    
            }else if(cardInfos == 'error'){
    
                return (
                        
                    <div class="text-dark mt-4">
    
                        <p>Erro ao obter os dados das partidas</p>
    
                    </div>
                
                );
    
            }else if(cardInfos == 'test'){
    
                return (
                        
                    <div class="text-dark mt-4">
    
                        <p>{test}</p>
    
                    </div>
                
                );
    
            }else{
    
                const localCardInfos = cardInfos.maches;

                function renderChannels(channels){
    
                    const channelsComponent = channels.map((channelSrc) => {
        
                        return (<img class="object-fit-contain me-2 p-1 rounded border border-dark" src={channelSrc} alt="" style={{width:70, backgroundColor: '#ccc'}}/>);
        
                    });
        
                    return (
                        <>
                            {channelsComponent}
                        </>
                    );
        
                }
    
                const cards = localCardInfos.map((match) => {

                    const localEditItem = () => { openEditMatch(match.id); }
                    const localDeletItem = () => { deleteMatch(match.id); }

                    var localMatchId;
                    var localOriginApi;

                    if(match.origin_api == "manual" || match.origin_api == "e_manual"){

                        localMatchId = match.id;

                        if(match.origin_api == "manual"){

                            localOriginApi = <Badge bg="primary">Manual</Badge>

                        }else{

                            localOriginApi = <Badge bg="secondary">Manual</Badge>

                        }

                    }else{

                        localMatchId = match.mach_id;

                        if(match.origin_api == "e_the_sports_db"){

                            localOriginApi = localOriginApi = <Badge bg="secondary">API</Badge>

                        }else{

                            localOriginApi = localOriginApi = <Badge bg="success">API</Badge>

                        }

                    }

                    return(
    
                        <div class="row d-flex">
            
                            <div class="d-flex col-9">
            
                                <Card className="mt-3 border-dark col-12 card">
            
                                    <Card.Header className="d-flex justify-content-between">
                                        
                                        <div className="d-flex align-items-center">
            
                                            {localOriginApi}
                                            <span class="ms-2">{match.league_name}</span>
                                            <span class="fw-lighter ms-1">{match.league_description}</span>
            
                                        </div>
            
                                        <div class="d-flex align-items-center">

                                            <p class="me-2 my-auto">#{localMatchId}</p>
            
                                            <img class="object-fit-contain p-1 rounded border border-dark" src={match.league_shield} alt="Time 1"  width="40" style={{ backgroundColor: '#ccc' }}/>
            
                                        </div>
                                        
                                    </Card.Header>     
            
                                    <Card.Body className="text-dark d-flex">
            
                                        <div class="col-7 d-flex">
            
                                            <div class="">
            
                                                <div class="row justify-content-center">
            
                                                    <img class="object-fit-contain" src={match.team_1_shield} alt="Time 1" style={{width:75}}/>
            
                                                </div>
            
                                                <div class="row justify-content-center">
                                                    <span id="text-team" class="text-center mt-2">{match.team_1_name}</span>
                                                </div>
            
                                            </div>
            
                                            <div class="d-flex justify-content-center align-items-center px-4">
                                                <span>x</span>
                                            </div>
            
                                            <div class="">
            
                                                <div class="row justify-content-center">
            
                                                    <img class="object-fit-contain" src={match.team_2_shield} style={{width:75}} />
            
                                                </div>
            
                                                <div class="row justify-content-center">
                                                    <span id="text-team" class="text-center mt-2">{match.team_2_name}</span>
                                                </div>
            
                                            </div>
            
                                        </div>
            
                                        <div class="col-5 d-flex justify-content-center align-items-center">
            
                                            <span id="text-hour-play" class="text-center fw-bold" style={{fontSize: 60, color: '#193150'}}>{match.mach_time}</span>
            
                                        </div>
            
                                        
                                    </Card.Body>
            
                                    <Card.Footer class="card-footer d-flex justify-content-between align-items-center">
            
                                        <span class="me-2">Onde assistir: </span>
            
                                        <div class="row">
            
                                            {renderChannels(match.mach_channels)}
    
                                        </div>
            
                                    </Card.Footer>
            
                                </Card>
            
                            </div>
            
                            <div class="col-3 mt-3 ps-0 justify-content-start d-flex flex-column align-items-start">
            
                                <a href="#" onClick={localEditItem} style={{width:40, height:40}} class="rounded border border-dark d-flex justify-content-center align-items-center"><Icon.PencilFill color="#000"/></a>
                                <a href="#" onClick={localDeletItem} style={{width:40, height:40}} class="mt-2 rounded border border-dark d-flex justify-content-center align-items-center"><Icon.TrashFill color="#000"/></a>
            
                            </div>
            
                        </div>  
            
                    );
    
                });
    
                if(localCardInfos.length == 0){
    
                    return (
                        
                        <div class="text-dark mt-4">
    
                            <p>Não foram encontrados jogos para este dia</p>
    
                        </div>
                    
                    );
    
                }else{
    
                    return cards;
    
                }
    
            }
            
        }catch(error){

            setMatches('error');
            
        }

    }

    function setDayCardsNow(){

        // setDayCards('now')

    }

    function setDayCardsYesterday(){

        setDayCards('yesterday');

    }

    function setDayCardsToday(){

        setDayCards('today')

    }

    function setDayCardsTomorrow(){

        setDayCards('tomorrow')

    }

    function renderServerStatus(){

        if(serverStatusOn){
            
            return(

                <Icon.CircleFill color="green" className='d-flex mt-1 me-2'/>

            );

        }else{

            return (

                <Icon.CircleFill color="red" className='d-flex mt-1 me-2'/>

            );

        }

    }

    function renderButtonWithLoading(onClickFunction, text, status){

        if(status == 'loading'){

            return(
            
                <Button disabled={busy} onClick={onClickFunction} variant="outline-primary">

                    <Spinner

                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                        
                    />

                   {text}
                    
                </Button>
            
            );

        }else{

            return(
            
                <Button disabled={busy} onClick={onClickFunction} variant="outline-primary">

                   {text}
                    
                </Button>
            
            );

        }

    }

    function navigateToRegisters(){

        navigate('/registers');

    }

    useEffect(() => {

        getServerStatus();

        getMatches(dayCards);

    }, [dayCards, successAction]);

    useEffect(() => {

        if(dayFetch != 'null'){
        
            fetchMatches(dayFetch);
        
        }

    }, [dayFetch]);

    useEffect(() => {

        if(daySync != 'null'){

            syncMatches(daySync);

        }

    }, [daySync]);

    return(

        <div className='page-container'>

            <div className="navbar-container bg-body-tertiary">

                <Navbar></Navbar>

            </div>

            <div className="row main-container text-light vh-100 pt-3 col-10 mx-auto">

                <div className="col-8 pb-4">

                    <ButtonGroup className="d-flex" aria-label="Basic radio toggle button group">

                        <Button disabled={busy} onClick={setDayCardsNow} variant="outline-primary" active={nowActive} >Passando Agora</Button>
                        <Button disabled={busy} onClick={setDayCardsYesterday} variant="outline-primary" active={yesterdayActive} >Jogos Ontem</Button>
                        <Button disabled={busy} onClick={setDayCardsToday} variant="outline-primary" active={todayActive} >Jogos Hoje</Button>
                        <Button disabled={busy} onClick={setDayCardsTomorrow} variant="outline-primary" active={tomorrowActive} >Jogos Amanhã</Button>

                    </ButtonGroup>

                    {renderCards(matches)}                

                </div>

                <div class="col-1"></div>

                <div className="col-3 d-flex flex-column">

                    <div class="card mb-2 pt-3 ps-3">

                        <div class="text-dark d-flex">

                            { renderServerStatus() }

                            <p>Back-end</p>
                            
                        </div>

                    </div>

                    <ButtonGroup vertical>

                        <p className="text-dark">Ferramentas</p>

                        <Button disabled={busy} onClick={openAddMatch} variant="outline-primary">Cadastrar Evento</Button>
                        <Button disabled={busy} onClick={navigateToRegisters} variant="outline-primary">Selecionar Ligas</Button>

                    </ButtonGroup>

                    <ButtonGroup vertical className="mt-3">

                        <p className="text-dark">Serviços do Back-end</p>

                        {renderButtonWithLoading(fetchMatchesYesterday, 'Fetch Jogos Ontem', buttonFetchYesterdayState)}
                        {renderButtonWithLoading(fetchMatchesToday, 'Fetch Jogos Hoje', buttonFetchTodayState)}
                        {renderButtonWithLoading(fetchMatchesTomorrow, 'Fetch Jogos Amanhã', buttonFetchTomorrowState)}
                        {renderButtonWithLoading(deleteAll, 'Apagar Todos', buttonDeleteAllState)}
                        
                    </ButtonGroup>

                    <ButtonGroup vertical className="mt-3">

                        <p className="text-dark">Sincronizar Front-end</p>

                        {renderButtonWithLoading(syncMatchesYesterday, 'Update Jogos Ontem', buttonSyncYesterdayState)}
                        {renderButtonWithLoading(syncMatchesToday, 'Update Jogos Hoje', buttonSyncTodayState)}
                        {renderButtonWithLoading(syncMatchesTomorrow, 'Update Jogos Amanhã', buttonSyncTomorrowState)}

                    </ButtonGroup>

                    <ButtonGroup vertical className="mt-3">

                        <p className="text-dark">Ações</p>

                        <Button onClick={handleLogout} variant="outline-danger">Sair</Button>
                        
                    </ButtonGroup>

                </div>


            </div>

            {/* <div class="footer text-dark">

                <p>{test}</p>

            </div> */}


        </div>

    );

}

export default Home;