import { useNavigate } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import logo from './../../img/darkIcon.png';

function MyNavbar(){

    const navigate = useNavigate();

    function navigateToHome(){

        navigate('/');

    }

    function navigateToRegisters(){

        navigate('/registers');

    }

    function navigateToProfile(){

        navigate('/profile');

    }

    return(

        <Navbar expand="lg" className="justify-content-between col-10 mx-auto">

            <div className=''>

                <Navbar.Brand href="/">

                    <img
                        alt=""
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />

                    {' '} Futnow

                </Navbar.Brand>

            </div>

            <div className=''>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">

                    <Nav className="me-auto">

                        <Nav.Link onClick={navigateToHome} href="#">Home</Nav.Link>
                        <Nav.Link onClick={navigateToRegisters} href="#">Cadastros</Nav.Link>
                        <Nav.Link onClick={navigateToRegisters} href="#">Anúncios</Nav.Link>
                        <Nav.Link onClick={navigateToProfile} href="#">Perfil</Nav.Link>

                    </Nav>

                </Navbar.Collapse>
            
            </div>

        </Navbar>

    );

}

export default MyNavbar;