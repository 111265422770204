import { useState, useEffect, useContext, useRef } from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';

import { Context } from "./../../../context/authContext";

import SelectWithSearch from './../SelectWithSearch';
import be_api from './../../../modules/be_api';

import upload_blank_img from './../../../img/upload_blank.png';

function MyForm(props){

    const submitButtonRef = useRef(null);
    const teamNameRef = useRef(null);
    const leaguesInputRef = useRef(null);
    const imgRef = useRef(null);

    const { modalButtonAction, setSuccessAction } = useContext(Context);

    const [leaguesList, setLeaguesList] = useState();
    const [selectedLeagues, setSelectedLeagues] = useState();

    const [isRequired, setIsRequired] = useState(true);
    const [file, setFile] = useState();
    const [imageHash, setImageHash] = useState(Date.now());

    async function getEdit(id){

        setIsRequired(false);

        setImageHash(Date.now());
    
        const url = '/return_team?id=' + id;

        await be_api.get(url).then(function(response){

            console.log(response.data);

            if(response.data.status == 'sucesso'){

                teamNameRef.current.value = response.data.team.translated_team_name;

                imgRef.current.src = 'https://api.futnow.net/images/teams/' + id + '.png?' + imageHash;

                // leaguesInputRef.current.defaultValue = response.data.team.leagues;

                // defaultValue

                //Colocar também as ligas as quais ele pertence, aqui na edição;

            }else{

                setSuccessAction(false);

            }

        }).catch(function (error){

            setSuccessAction(false);

        });

    }

    function handleChange(e){

        setFile(e.target.files[0]);

        imgRef.current.src = URL.createObjectURL(e.target.files[0]);

    }
    
    async function getLeagues(){

        const response = await be_api.get('/return_leagues');

        if(response.data.status == 'sucesso'){

            const leaguesOptions = response.data.leagues.map((league) => {

                return {
    
                    value: league.id,
                    label: league.translated_league_name
    
                }
    
            });
            
            setLeaguesList(leaguesOptions);

        }
        
    } 

    function parseSelectedLeagues(e){

        const parsedLeagues = e.map((league) => {

            return league.value;

        });

        setSelectedLeagues(parsedLeagues);

    }

    async function submitForm(e){

        e.preventDefault();

        try {

            var url;
            const bodyFormData = new FormData();

            if(props.edit){

                bodyFormData.append("id", props.id);
                bodyFormData.append("original_team_name", e.target.team_name.value);
                bodyFormData.append("translated_team_name", e.target.team_name.value);
                bodyFormData.append("leagues", selectedLeagues);

                url = '/change_team';


            }else{

                bodyFormData.append("original_team_name", e.target.team_name.value);
                bodyFormData.append("origin_api", "manual");
                bodyFormData.append("translated_team_name", e.target.team_name.value);
                bodyFormData.append("leagues", selectedLeagues);
                bodyFormData.append("selected_to_search", false);

                url = '/create_team';

            }

            bodyFormData.append("team_shield", file);

            const response = await be_api.post(url, bodyFormData, {'Content-Type': 'multipart/form-data'});

            if(response.data.status == 'sucesso'){

                setSuccessAction(true);

            }else{

                setSuccessAction(false);

            }

            console.log(response.data);

        }catch(error){

            console.log('erro');

            setSuccessAction(false);
            
        }
        
    }

    //Obtém ligas e verifica se está no modo de edição quando monta o componente
    useEffect(() => {

        getLeagues();

        if(props.edit){

            getEdit(props.id);

        }

    }, []);

    //Recebe via contexto o clique do botão externo do modal p/ submit do form
    useEffect(() => {

        if(modalButtonAction){

            submitButtonRef.current.click();

        }

    }, [modalButtonAction]);

    return(

        <Form onSubmit={submitForm} className="row pe-2">

            <div class="col-8">
            
                <Form.Group className="mb-3" controlId="formBasicEmail">

                    <SelectWithSearch ref={leaguesInputRef} defaultValue={[]} isMulti onChange={parseSelectedLeagues} name="leagues" options={leaguesList} placeholder="Pesquise as Ligas" />
                
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">

                    <Form.Control required ref={teamNameRef} name="team_name" type="text" placeholder="Nome do time" />

                </Form.Group>

                <Form.Group controlId="formFile" className="">
                    <Form.Label>Escudo do time</Form.Label>
                    <Form.Control accept=".png" required={ isRequired } onChange={handleChange} name="file" type="file" />
                </Form.Group>

            </div>

            <div class="col-4 d-flex align-items-center justify-content-center rounded border border-dark" style={{ backgroundColor: '#ccc' }}>

                <Image style={{ height:150 }} ref={ imgRef } fluid src={ upload_blank_img } />

            </div>

            <Button className='d-none' ref={submitButtonRef} variant="primary" type="submit">Submit</Button>

        </Form>

    );

}

export default MyForm;