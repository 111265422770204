import Navbar from "./../../components/Navbar";
import be_api from "./../../modules/be_api";

function Profile(){

    return(

        <div className='page-container'>

            <div className="navbar-container bg-body-tertiary">

                <Navbar></Navbar>

            </div>

            <div className="row main-container text-dark vh-100 pt-3 col-10 mx-auto">
            
                Perfil

            </div>

        </div>

    );

}

export default Profile;