import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from "./../components/Modal";
import Toast from "./../components/Toast";

import be_api from "./../modules/be_api";

const Context = createContext();

function AuthProvider({ children }){

    const navigate = useNavigate();

    const [autenticated, setAutenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [authError, setAuthError] = useState(false);

    const [modalLoading, setModalLoading] = useState(true);
    const [modalOn, setModalOn] = useState(false);
    const [modalTitle, setModalTitle] = useState('Modal');
    const [modalContent, setModalContent] = useState('Conteúdo');
    const [modalButtonText, setModalButtonText] = useState('Aplicar');
    const [modalButtonAction, setModalButtonAction] = useState(false);

    const [successAction, setSuccessAction] = useState(undefined);

    const [toastOn, setToastOn] = useState(false);
    const [toastTitle, setToastTitle] = useState('Alerta');
    const [toastDescription, setToastDescription] = useState('agora');
    const [toastContent, setToastContent] = useState('Mensagem de alerta!');
    const [toastType, setToastType] = useState('info');

    const handleModalClose = () => setModalOn(false);

    const triggerModal = (type, content) => {

        setModalLoading(true);

        if(type == "create"){

            setModalTitle('Criar');

            setModalButtonText('Criar'); 

        }else if(type == "edit"){

            setModalTitle('Editar');

            setModalButtonText('Editar'); 

        }        

        setModalContent(content);

        setModalLoading(false);

        setModalOn(true);

    }

    const triggerToast = (type, content) => {

        if(type == "success"){

            setToastTitle('Sucesso');         
            setToastType('success');

        }else if(type == "danger"){

            setToastTitle('Erro');
            setToastType('danger');

        }else{

            setToastTitle('Alerta');
            setToastType('alert');

        }

        setToastDescription('Agora');
        setToastContent(content);
        setToastOn(true);

    }

    //Verifica se tem token armazenado no localStorage p/ iniciar seção
    useEffect(() => {

        const token = localStorage.getItem('token');

        if(token){

            be_api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
            
            setAutenticated(true);

        }

        setLoading(false);

    }, []);

    //Troca o estado do botão do modal, para funcionar como toogle
    useEffect(() => {
        
        setModalButtonAction(false);

    }, [modalButtonAction]);

    //Dispara a notificação, e fecha o modal;
    useEffect(() => {

        if(successAction != undefined){

            setModalOn(false);

            if(successAction){

                triggerToast('success', 'Operação realizada com sucesso!');

            }else{

                triggerToast('danger', 'Erro ao tentar realizar operação!');

            }

            setSuccessAction(undefined);

        }

    }, [successAction]);

    async function handleLogin(e){

        e.preventDefault();

        setLoading(true);

        try {

            const bodyParams = {

                "email": e.target.email.value,
                "password": e.target.password.value

            };

            const response = await be_api.post('/auth_user', bodyParams);

            console.log(response.data);

            if(response.data.status == 'sucesso'){

                setAuthError(false);

                setAutenticated(true);

                localStorage.setItem('token', JSON.stringify(response.data.token));

                be_api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

                setLoading(false);

                console.log(autenticated);

                navigate('/');

            }else{

                setAutenticated(false);

                setAuthError(true);

                setLoading(false);

            }

        }catch(error){

            setAuthError(true);

            setLoading(false);
            
        }

    }

    async function handleLogout(){

        setLoading(true);

        setAuthError(false);

        setAutenticated(false);

        localStorage.removeItem('token');

        be_api.defaults.headers.Authorization = undefined;

        setLoading(false);

        navigate('/login');

    }

    return(

        <Context.Provider 
        
            value = {{ 
                 
                handleLogin, 
                handleLogout, 
                autenticated,
                loading, 
                authError, 

                triggerModal, 
                modalButtonAction,

                setSuccessAction, 
                successAction 
            }}

        >
            
            {children}

            <Modal 
                show = {modalOn} 
                onHide = {handleModalClose}
                title = {modalTitle}
                content = {modalContent}
                loading = {modalLoading}
                btnText = {modalButtonText}
                btnAction={setModalButtonAction}
            />

            <Toast 
                show={toastOn}
                title={toastTitle}
                description={toastDescription}
                content={toastContent}
                type={toastType}
                setToastOn={setToastOn}
            />

        </Context.Provider>
        
    );

}

export { Context, AuthProvider }