import {Button, Modal, Spinner} from 'react-bootstrap';

function MyModal(props){

    function renderContent(loading, content){

        if(loading){

            return(

                <div class="d-flex justify-content-center my-4">

                    <Spinner animation="border" role="status">

                        <span className="visually-hidden">Loading...</span>

                    </Spinner>

                </div>

            );

        }else{

            return(content);

        }

    }

    function btnAction(){

        props.btnAction(true);

    }

    return(
  
        <Modal size="lg" {...props}>

            <Modal.Header closeButton>

                <Modal.Title>{props.title}</Modal.Title>

            </Modal.Header>

            <Modal.Body>

                {renderContent(props.loading, props.content)}
                
            </Modal.Body>
            
            <Modal.Footer>

                <Button variant="primary" onClick={btnAction}>

                    {props.btnText}
                    
                </Button>

            </Modal.Footer>

        </Modal>
  
    );
  
}
  
export default MyModal;