import { useState, useEffect, useContext} from "react";
import { ButtonGroup, Button, ListGroup, Form } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';

import Navbar from "./../../components/Navbar";

import LeagueForm from "./../../components/Forms/FormLeague";
import TeamForm from "./../../components/Forms/FormTeam";
import ChannelForm from "./../../components/Forms/FormChannel";

import { Context } from "./../../context/authContext";

import be_api from "./../../modules/be_api";

function Registers(){

    const { triggerModal, setSuccessAction, successAction } = useContext(Context);

    const [leaguesActive, setLeaguesActive] = useState(true);
    const [teamsActive, setTeamsActive] = useState(false);
    const [channelsActive, setChannelsActive] = useState(false);

    const [apiLeagues, setApiLeagues] = useState([]);
    const [manualLeagues, setManualLeagues] = useState([]);
    const [apiTeams, setApiTeams] = useState([]);
    const [manualTeams, setManualTeams] = useState([]);
    const [apiChannels, setApiChannels] = useState([]);
    const [manualChannels, setManualChannels] = useState([]);

    function activateLeagues(){

        setLeaguesActive(true);
        setTeamsActive(false);
        setChannelsActive(false);

    }

    function activateTeams(){

        setLeaguesActive(false);
        setTeamsActive(true);
        setChannelsActive(false);

    }

    function activateChannels(){

        setLeaguesActive(false);
        setTeamsActive(false);
        setChannelsActive(true);

    }

    async function getLeagues(){

        const url = '/return_api_leagues?origin_api=the_sports_db';

        await be_api.get(url).then(function(response){

            if(response.data.status == 'sucesso'){

                setApiLeagues(response.data.leagues);

            }else{

                setApiLeagues('error');

            }

        }).catch(function (error){

            setApiLeagues('error');

        });

        const url2 = '/return_api_leagues?origin_api=manual';

        await be_api.get(url2).then(function(response){

            if(response.data.status == 'sucesso'){

                setManualLeagues(response.data.leagues);

            }else{

                setManualLeagues('error');

            }

        }).catch(function (error){

            setManualLeagues('error');

        });

    }

    async function getTeams(){

        const url = '/return_teams_by_api?origin_api=the_sports_db';

        await be_api.get(url).then(function(response){

            if(response.data.status == 'sucesso'){

                setApiTeams(response.data.teams);

            }else{

                setApiTeams('error');

            }

        }).catch(function (error){

            setApiTeams('error');

        });

        const url2 = '/return_teams_by_api?origin_api=manual';

        await be_api.get(url2).then(function(response){

            if(response.data.status == 'sucesso'){

                setManualTeams(response.data.teams);

            }else{

                setManualTeams('error');

            }

        }).catch(function (error){

            setManualTeams('error');

        });

    }

    async function getChannels(){

        const url = '/return_channels_by_api?origin_api=the_sports_db';

        await be_api.get(url).then(function(response){

            if(response.data.status == 'sucesso'){

                setApiChannels(response.data.channels);

            }else{

                setApiChannels('error');

            }

        }).catch(function (error){

            setApiChannels('error');

        });

        const url2 = '/return_channels_by_api?origin_api=manual';

        await be_api.get(url2).then(function(response){

            if(response.data.status == 'sucesso'){

                setManualChannels(response.data.channels);

            }else{

                setManualChannels('error');

            }

        }).catch(function (error){

            setManualChannels('error');

        });

    }

    function openAddLeague(){

        triggerModal("create", <LeagueForm edit={false} />);

    };

    function openEditLeague(id){
        
        triggerModal("edit", <LeagueForm edit={true} id={id} />);

    }

    async function deleteLeague(id){

        const url = '/delete_league?id=' + id;

        await be_api.delete(url).then(function(response){

            if(response.data.status == 'sucesso'){

                setSuccessAction(true);

            }else{

                setSuccessAction(false);

            }

        }).catch(function (error){

            setSuccessAction(false);

        });

    }

    function openAddTeam(){

        triggerModal("create", <TeamForm edit={false} />);

    };

    function openEditTeam(id){

        triggerModal("edit", <TeamForm edit={true} id={id} />);

    }

    async function deleteTeam(id){

        console.log('delete: ', id);

        const url = '/delete_team?id=' + id;

        await be_api.delete(url).then(function(response){

            if(response.data.status == 'sucesso'){

                setSuccessAction(true);

            }else{

                setSuccessAction(false);

            }

        }).catch(function (error){

            setSuccessAction(false);

        });

    }

    function openAddChannel(){

        triggerModal("create", <ChannelForm edit={false} />);

    };

    function openEditChannel(id){
        
        triggerModal("edit", <ChannelForm edit={true} id={id} />);

    }

    async function deleteChannel(id){

        console.log('delete: ', id);

        const url = '/delete_channel?id=' + id;

        await be_api.delete(url).then(function(response){

            if(response.data.status == 'sucesso'){

                setSuccessAction(true);

            }else{

                setSuccessAction(false);

            }

        }).catch(function (error){

            setSuccessAction(false);

        });

    }

    const alertClicked = (e) => {

        console.log(e.target);

    };

    function renderChecked(status){

        if(status == 'true'){

            return(true);

        }else{

            return(false);

        }

    }

    function renderManualLeaguesList(){

        const leaguesList = manualLeagues.toReversed().map((league) => {

            const localEditItem = () => { openEditLeague(league.id); }
            const localDeletItem = () => { deleteLeague(league.id); }

            return(
            
                <ListGroup.Item 
                    // action 
                    // onClick={alertClicked} 
                    value={league.id}
                    className="d-flex justify-content-between"
                >
                    
                    {league.translated_league_name}

                    <div class="d-flex">

                        <a href="#" onClick={localEditItem} style={{width:22, height:22}} class="m-0 mt-1 d-flex border border-dark rounded p-1 bg-dark justify-content-center align-items-center"><Icon.PencilFill color="white"/></a>
                        <a href="#" onClick={localDeletItem} style={{width:22, height:22}} class="m-0 ms-2 mt-1 d-flex border border-dark rounded p-1 bg-dark justify-content-center align-items-center"><Icon.TrashFill color="red"/></a>
            
                    </div>
                
                </ListGroup.Item>
            
            );

        });

        if(leaguesList.length != 0){

            return(

                <ListGroup>
    
                    {leaguesList}
    
                </ListGroup>
    
            );

        }else{

            return(

                <p class="ms-2">Não foram encontradas ligas nesta categoria.</p>
    
            );

        }

    }
    
    function renderApiLeaguesList(){

        const leaguesList = apiLeagues.toReversed().map((league) => {

            const localChecked = renderChecked(league.selected_to_search);

            return(
            
                <ListGroup.Item 
                    action 
                    onClick={alertClicked} 
                    value={league.id}
                    className="d-flex justify-content-between"
                >
                    
                    {league.translated_league_name}

                    <Form.Check
                        inline
                        checked={localChecked}
                        // onClick={changeStatus}
                        name="status"
                        type="switch"
                        id="status"
                        
                    />
                
                </ListGroup.Item>
            
            );

        });

        if(leaguesList.length != 0){

            return(

                <ListGroup>
    
                    {leaguesList}
    
                </ListGroup>
    
            );

        }else{

            return(

                <p class="ms-2">Não foram encontradas ligas nesta categoria.</p>
    
            );

        }

    }

    function renderManualTeamsList(){

        const teamsList = manualTeams.toReversed().map((team) => {

            const localEditItem = () => { openEditTeam(team.id); }
            const localDeletItem = () => { deleteTeam(team.id); }

            return(
            
                <ListGroup.Item 
                    
                    value={team.id}
                    className="d-flex justify-content-between"

                >
                    
                    {team.translated_team_name}

                    <div class="d-flex">

                        <a href="#" onClick={localEditItem} style={{width:22, height:22}} class="m-0 mt-1 d-flex border border-dark rounded p-1 bg-dark justify-content-center align-items-center"><Icon.PencilFill color="white"/></a>
                        <a href="#" onClick={localDeletItem} style={{width:22, height:22}} class="m-0 ms-2 mt-1 d-flex border border-dark rounded p-1 bg-dark justify-content-center align-items-center"><Icon.TrashFill color="red"/></a>
            
                    </div>
                
                </ListGroup.Item>
            
            );

        });

        if(teamsList.length != 0){

            return(

                <ListGroup>
    
                    {teamsList}
    
                </ListGroup>
    
            );

        }else{

            return(

                <p class="ms-2">Não foram encontrados times nesta categoria.</p>
    
            );

        }

    }

    function renderApiTeamsList(){

        const teamsList = apiTeams.toReversed().map((team) => {

            return(
            
                <ListGroup.Item 
                    action 
                    onClick={alertClicked} 
                    value={team.id}
                    className="d-flex justify-content-between"
                >
                    
                    {team.translated_team_name}

                    <Form.Check
                        inline

                        // checked={true}
                        // onClick={changeStatus}
                        name="status"
                        type="switch"
                        id="status"
                        
                    />
                
                </ListGroup.Item>
            
            );

        });

        if(teamsList.length != 0){

            return(

                <ListGroup>
    
                    {teamsList}
    
                </ListGroup>
    
            );

        }else{

            return(

                <p class="ms-2">Não foram encontradas times nesta categoria.</p>
    
            );

        }

    }

    function renderManualChannelsList(){

        const channelsList = manualChannels.toReversed().map((channel) => {

            const localEditItem = () => { openEditChannel(channel.id); }
            const localDeletItem = () => { deleteChannel(channel.id); }

            return(
            
                <ListGroup.Item 
                
                    value={channel.id}
                    className="d-flex justify-content-between"
                    
                >
                    
                    {channel.name}

                    <div class="d-flex">

                        <a href="#" onClick={localEditItem} style={{width:22, height:22}} class="m-0 mt-1 d-flex border border-dark rounded p-1 bg-dark justify-content-center align-items-center"><Icon.PencilFill color="white"/></a>
                        <a href="#" onClick={localDeletItem} style={{width:22, height:22}} class="m-0 ms-2 mt-1 d-flex border border-dark rounded p-1 bg-dark justify-content-center align-items-center"><Icon.TrashFill color="red"/></a>
            
                    </div>
                
                </ListGroup.Item>
            
            );

        });

        if(channelsList.length != 0){

            return(

                <ListGroup>
    
                    {channelsList}
    
                </ListGroup>
    
            );

        }else{

            return(

                <p class="ms-2">Não foram encontrados canais nesta categoria.</p>
    
            );

        }

    }

    function renderApiChannelsList(){

        const channelsList = apiChannels.toReversed().map((channel) => {

            return(
            
                <ListGroup.Item 
                    action 
                    onClick={alertClicked} 
                    value={channel.id}
                    className="d-flex justify-content-between"
                >
                    
                    {channel.name}

                    <Form.Check
                        inline

                        // checked={true}
                        // onClick={changeStatus}
                        name="status"
                        type="switch"
                        id="status"
                        
                    />
                
                </ListGroup.Item>
            
            );

        });

        if(channelsList.length != 0){

            return(

                <ListGroup>
    
                    {channelsList}
    
                </ListGroup>
    
            );

        }else{

            return(

                <p class="ms-2">Não foram encontrados canais nesta categoria.</p>
    
            );

        }

    }

    function renderContent(){

        if(leaguesActive){

            return (

                <>

                    <p class="mt-3">Ligas Manuais</p>

                    { renderManualLeaguesList() }

                     <p class="mt-3">Ligas da API</p>

                    { renderApiLeaguesList() }

                </>

            );

        }else if(teamsActive){

            return (

                <>

                    <p class="mt-3">Times Manuais</p>

                    { renderManualTeamsList() }

                     <p class="mt-3">Times da API</p>

                    { renderApiTeamsList() }

                </>

            );

        }else if(channelsActive){

            return (

                <>

                    <p class="mt-3">Canais Manuais</p>

                    { renderManualChannelsList() }

                     <p class="mt-3">Canais da API</p>

                    { renderApiChannelsList() }

                </>

            );

        }else{

            return(<p class="mt-3">loading...</p>)

        }

    }

    useEffect(() => {

        getLeagues();
        getTeams();
        getChannels();

    }, [successAction]);

    return(

        <div className='page-container'>

            <div className="navbar-container bg-body-tertiary">

                <Navbar></Navbar>

            </div>

            <div className="row main-container text-dark vh-100 pt-3 col-10 mx-auto">

                <div className="col-8 pb-4">

                    <ButtonGroup className="d-flex mb-3" aria-label="Basic radio toggle button group">

                        <Button onClick={ activateLeagues } active={ leaguesActive } variant="outline-primary" >Ligas</Button>
                        <Button onClick={ activateTeams } active={ teamsActive } variant="outline-primary" >Times</Button>
                        <Button onClick={ activateChannels } active={ channelsActive } variant="outline-primary" >Canais</Button>

                    </ButtonGroup>

                    { renderContent() }

                </div>
            
                <div className="col-1">

                </div>

                <div className="col-3 d-flex flex-column">

                <ButtonGroup vertical>

                    <p className="text-dark">Ferramentas</p>

                    <Button onClick={openAddLeague} variant="outline-primary">Cadastrar Liga</Button>
                    <Button onClick={openAddTeam} variant="outline-primary">Cadastrar Time</Button>
                    <Button onClick={openAddChannel} variant="outline-primary">Cadastrar Canal</Button>

                </ButtonGroup>


                </div>
            
            </div>

        </div>

    );

}

export default Registers;