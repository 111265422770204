import { React, useContext } from 'react';

import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';

import { AuthProvider, Context } from './context/authContext';

import LocalizationProvider from './context/datePickerContext';

import Login from './pages/login';
import Home from './pages/home';
import Registers from './pages/registers';
import Profile from './pages/profile';

function ProtectedRoute({ children }){

    const navigate = useNavigate();

    const { loading, autenticated } = useContext(Context);
  
    if(loading){

        return <p class="text-dark">Loading...</p>;

    }

    if(!autenticated){

        navigate('/login');

    }else{

        return children;

    }

};

export default function MyRoutes(){

    return(

            <BrowserRouter>

                <LocalizationProvider>

                    <AuthProvider>

                        <Routes>

                            <Route exact path="/login" element={<Login />} />

                            <Route exact path="/" element={<ProtectedRoute> <Home /> </ProtectedRoute>} />
                            <Route exact path="/registers" element={<ProtectedRoute> <Registers /> </ProtectedRoute>} />
                            <Route exact path="/profile" element={<ProtectedRoute> <Profile /> </ProtectedRoute>} />

                        </Routes>

                    </AuthProvider>

                </LocalizationProvider>

            </BrowserRouter>

    );
}