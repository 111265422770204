import React from 'react'
import Select from 'react-select'

function MySelectWithSearch(props){

    return(

        <Select {...props}/>

    );

}

export default MySelectWithSearch;