import 'bootstrap/dist/css/bootstrap.min.css';

import Routes from './routes';

function App(){

  return(

    <Routes />

  );

}

export default App;
