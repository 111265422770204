import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

function MyLocalizationProvider({ children }){

    return (
        
        <LocalizationProvider dateAdapter={AdapterDayjs}>

            {children}
            
        </LocalizationProvider>

    );

}

export default MyLocalizationProvider;