import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import * as Icon from 'react-bootstrap-icons';

function MyToast(props) {

    function renderIcon(){

        if(props.type == 'danger'){

            return(
                <Icon.ExclamationCircleFill color="red" className='me-2'/>
            );

        }else if(props.type == 'success'){

            return(
                <Icon.CheckCircleFill color="green" className='me-2'/>
            );

        }else{

            return(
                <Icon.ExclamationCircleFill color="#000" className='me-2'/>
            );

        }

    }
  
    return(

        <ToastContainer

            className="p-3 position-fixed"
            position="top-end"
            style={{ zIndex: 1 }}

        >

            <Toast {...props} 
            
                onClose={() => props.setToastOn(false)} 
                show={props.show}
                delay={5000} 
                autohide
            
            >
                
                <Toast.Header>

                    { renderIcon() }

                    <strong className="me-auto">{props.title}</strong>

                    <small>{props.description}</small>

                </Toast.Header>

                <Toast.Body>{props.content}</Toast.Body>
            
            </Toast>

        </ToastContainer>

    );

}
  
export default MyToast;
