import { useState, useEffect, useContext, useRef } from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';

import { Context } from "./../../../context/authContext";

import be_api from './../../../modules/be_api';

import upload_blank_img from './../../../img/upload_blank.png';

function MyForm(props){

    const submitButtonRef = useRef(null);
    const channelNameRef = useRef(null);
    const imgRef = useRef(null);
    
    const { modalButtonAction, setSuccessAction } = useContext(Context);

    const [isRequired, setIsRequired] = useState(true);
    const [file, setFile] = useState();
    const [imageHash, setImageHash] = useState(Date.now());

    async function getEdit(id){

        setIsRequired(false);

        setImageHash(Date.now());
    
        const url = '/return_channel?id=' + id;

        await be_api.get(url).then(function(response){

            if(response.data.status == 'sucesso'){

                channelNameRef.current.value = response.data.channel.name;

                imgRef.current.src = 'https://api.futnow.net/images/channels/' + id + '.png?' + imageHash;

            }else{

                setSuccessAction(false);

            }

        }).catch(function (error){

            setSuccessAction(false);

        });

    }

    function handleChange(e){

        setFile(e.target.files[0]);

        imgRef.current.src = URL.createObjectURL(e.target.files[0]);

    }

    async function submitForm(e){

        e.preventDefault();

        try {

            var url;
            const bodyFormData = new FormData();

            if(props.edit){

                bodyFormData.append("id", props.id);
                bodyFormData.append("name", e.target.channel_name.value);

                url = '/change_channel';

            }else{

                bodyFormData.append("name", e.target.channel_name.value);
                bodyFormData.append("origin_api", "manual");
                bodyFormData.append("selected_to_search", false);

                url = '/create_channel';

            }

            bodyFormData.append("logo", file);
            
            const response = await be_api.post(url, bodyFormData, {'Content-Type': 'multipart/form-data'});

            if(response.data.status == 'sucesso'){

                setSuccessAction(true);

            }else{

                setSuccessAction(false);

            }

            console.log(response.data);

        }catch(error){

            console.log('erro');

            setSuccessAction(true);

        }
        
    } 

    //Verifica se está no modo de edição quando monta o componente
    useEffect(() => {

        if(props.edit){

            getEdit(props.id);

        }

    }, []);

    //Recebe via contexto o clique do botão externo do modal p/ submit do form
    useEffect(() => {

        if(modalButtonAction){

            submitButtonRef.current.click();

        }

    }, [modalButtonAction]);

    return(

        <Form onSubmit={submitForm} className="row pe-2">

            <div class="col-8">

                <Form.Group className="mb-3" controlId="formBasicEmail">

                    <Form.Control required ref={channelNameRef} name="channel_name" type="text" placeholder="Nome do canal" />

                </Form.Group>

                <Form.Group controlId="formFile" className="">

                    <Form.Label>Imagem do canal</Form.Label>
                    <Form.Control accept=".png" required={ isRequired } onChange={handleChange} name="file" type="file" />
                    
                </Form.Group>

            </div>

            <div class="col-4 d-flex align-items-center justify-content-center rounded border border-dark" style={{ backgroundColor: '#ccc' }} >

                <Image style={{ height:100 }} ref={ imgRef } fluid src={ upload_blank_img } />

            </div>

            <Button className='d-none' ref={submitButtonRef} variant="primary" type="submit">Submit</Button>

        </Form>

    );

}

export default MyForm;