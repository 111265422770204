import { useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import logo from './../../img/icon.png'

import { Context } from "./../../context/authContext";

function Login(){

    const navigate = useNavigate();

    const { autenticated, handleLogin, authError, loading, setSuccessAction } = useContext(Context);

    if(autenticated && !authError){

        navigate('/');

    }

    useEffect(() => {

        if(authError){

            setSuccessAction(false);

        }

    }, [authError, loading]);

    return(

        <div className='page-container vh-100 d-flex'>

            <div className="row main-container text-dark pt-3 col-10 mx-auto d-flex justify-content-center my-auto">

                <div class="logo-container d-flex justify-content-center align-items-center mb-5">

                    <img
                        alt=""
                        src={logo}
                        width="80"
                        height="80"
                        className="d-inline-block align-top me-1"
                    />

                    <div class="ms-1 mt-3">

                        <h2>Futnow</h2>
                        <h6 class="">Administração</h6>

                    </div>

                </div>

                <div class="form-container d-flex justify-content-center align-items-start">
            
                    <div class="form-box col-4 shadow rounded p-4">

                        <Form className="" onSubmit={handleLogin}>

                            <Form.Group className="mb-3" controlId="formBasicEmail">

                                <Form.Label>Email</Form.Label>
                                <Form.Control name="email" type="email" placeholder="Digite seu email" />

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">

                                <Form.Label>Senha</Form.Label>
                                <Form.Control name="password" type="password" placeholder="Digite sua senha" />

                            </Form.Group>

                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="formBasicCheckbox">

                                <Form.Check checked type="checkbox" label="Manter logado" />
                                <Button variant="primary" type="submit">Login</Button>

                            </Form.Group>

                        </Form>

                    </div>

                </div>

            </div>

        </div>

    );

}

export default Login;