import { useState, useEffect, useContext, useRef } from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import { Context } from "./../../../context/authContext";

import SelectWithSearch from './../SelectWithSearch';
import be_api from './../../../modules/be_api';

function MyForm(props){

    const submitButtonRef = useRef(null);
    const timeRef = useRef(null);
    const descriptionRef = useRef(null);
    const dateRef = useRef(null);

    const { modalButtonAction, setSuccessAction } = useContext(Context);

    const [leaguesDisableds, setLeaguesDisableds] = useState(false);
    const [teamsDisableds, setTeamsDisableds] = useState(true);
    const [leaguesList, setLeaguesList] = useState();
    const [teamsList, setTeamsList] = useState();
    const [channelsList, setChannelsList] = useState();

    const [selectedLeague, setSelectedLeague] = useState();
    const [selectedTeam1, setSelectedTeam1] = useState();
    const [selectedTeam2, setSelectedTeam2] = useState();
    const [selectedChannels, setSelectedChannels] = useState();

    const [time, setTime] = useState();
    const [originApi, setOriginApi] = useState('manual');

    function convertTimeTo24(timeString){

        const splitedTime = timeString.split(' ');

        if(splitedTime[1] == 'AM'){

            if(parseInt(splitedTime[0].split(':')[0]) == 12){

                return '00:' + splitedTime[0].split(':')[1];

            }else{

                return splitedTime[0];

            }

        }else{

            if(parseInt(splitedTime[0].split(':')[0]) == 12){

                return splitedTime[0];

            }else{

                return (parseInt(splitedTime[0].split(':')[0]) + 12) + ':' + splitedTime[0].split(':')[1];   

            }

        }

    }

    function returnDayType(timeString){

        const yesterday = dayjs().add(-1, 'day').format('YYYY-MM-DD');
        const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');

        if(timeString == yesterday){

            return '2'

        }else if(timeString == tomorrow){

            return '3'

        }else{

            return '1'

        }

    }

    async function getEdit(id){ 

        setLeaguesDisableds(true);
    
        const url = '/return_match?id=' + id;

        await be_api.get(url).then(function(response){

            if(response.data.status == 'sucesso'){

                setOriginApi(response.data.match.origin_api);

                setTime(dayjs('2024-01-01T' + response.data.match.mach_time));

                dateRef.current.value = returnDayType(response.data.match.mach_date);

                descriptionRef.current.value = response.data.match.league_description.split('Rodada ')[1];

            }else{

                setSuccessAction(false);

            }

        }).catch(function (error){

            setSuccessAction(false);

        });

    }
    
    async function getLeagues(){

        const response = await be_api.get('/return_leagues');

        if(response.data.status == 'sucesso'){

            const leaguesOptions = response.data.leagues.map((league) => {

                return {
    
                    value: league.id,
                    label: league.translated_league_name
    
                }
    
            });
            
            setLeaguesList(leaguesOptions);

        }else{

            setSuccessAction(false);

        }
        
    } 

    async function getTeams(league_id){

        const response = await be_api.get('/return_teams_by_league?league_id=' + league_id);

        if(response.data.status == 'sucesso'){
            
            setTeamsList(response.data.teams);

        }else{

            setSuccessAction(false);

        }

    }

    async function getChannels(){

        const response = await be_api.get('/return_channels');

        if(response.data.status == 'sucesso'){

            const channelsOptions = response.data.channels.map((channel) => {

                return {
    
                    value: channel.id,
                    label: channel.name
    
                }
    
            });
            
            setChannelsList(channelsOptions);

        }else{

            setSuccessAction(false);

        }
        
    }

    function parseSelectedChannels(e){

        const parsedChannels = e.map((league) => {

            return league.value;

        });

        setSelectedChannels(parsedChannels);

    }

    async function enableTeamsOptions(e){

        getTeams(e.value);

        setTeamsDisableds(false);

    }

    async function submitForm(e){

        e.preventDefault();

        try {

            var bodyParams;
            var url;

            if(props.edit){

                bodyParams = {

                    "id": props.id,
                    "origin_api": originApi,
                    "mach_time": convertTimeTo24(e.target.match_time.value),
                    "mach_date": e.target.match_date.value,
                    "league_description": e.target.match_turn.value,
                    "mach_channels": selectedChannels,
    
                };

                url = '/change_match_by_dashboard';

            }else{

                bodyParams = {

                    "mach_time": convertTimeTo24(e.target.match_time.value),
                    "mach_date": e.target.match_date.value,
                    "league_id": e.target.match_league_id.value,
                    "league_description": e.target.match_turn.value,
                    "team_1_id": e.target.match_team_1_id.value,
                    "team_2_id": e.target.match_team_2_id.value,
                    "mach_channels": selectedChannels,
    
                };

                url = '/create_match_by_dashboard';

            }

            const response = await be_api.post(url, bodyParams);

            if(response.data.status == 'sucesso'){

                setSuccessAction(true);

            }else{

                setSuccessAction(false);

            }

        }catch(error){

            console.log('erro');

            setSuccessAction(false);
            
        }
        
    }

    //Obtém ligas, canais e verifica se está no modo de edição quando monta o componente
    useEffect(() => {

        getLeagues();
        getChannels();

        if(props.edit){

            getEdit(props.id);

        }

    }, []);

    //Recebe via contexto o clique do botão externo do modal p/ submit do form
    useEffect(() => {

        if(modalButtonAction){

            submitButtonRef.current.click();

        }

    }, [modalButtonAction]);

    return(

        <Form onSubmit={submitForm}>

            <Form.Group className="mb-3" controlId="formBasicEmail">

                <div class="d-flex justify-content-between">

                    <div class="col d-flex justify-content-start">

                        <MobileTimePicker ref={timeRef} value={time} name="match_time" defaultValue={dayjs('2024-01-01T16:00')}/>

                    </div>

                    <div class="col d-flex align-items-center">

                        <Form.Select ref={dateRef} name="match_date">

                            <option value="1">Hoje</option>
                            <option value="2">Ontem</option>
                            <option value="3">Amanhã</option>

                        </Form.Select>

                    </div>

                </div>

            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">

                <SelectWithSearch isDisabled={leaguesDisableds}  name="match_league_id" onChange={enableTeamsOptions} options={leaguesList} placeholder="Pesquise a Liga" />
            
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">

                <Form.Control ref={descriptionRef} name="match_turn" type="number" placeholder="Rodada" />

            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">

                <SelectWithSearch name="match_team_1_id" isDisabled={teamsDisableds} options={teamsList} placeholder="Selecione o Time 1"/>
            
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">

                <SelectWithSearch name="match_team_2_id" isDisabled={teamsDisableds} options={teamsList} placeholder="Selecione o Time 2"/>
            
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">

                <SelectWithSearch name="match_channels" isMulti onChange={parseSelectedChannels} options={channelsList} placeholder="Selecione os Canais"/>
            
            </Form.Group>

            <Button className='d-none' ref={submitButtonRef} variant="primary" type="submit">Submit</Button>

        </Form>

    );

}

export default MyForm;